import React from "react";

export const About = () => {
  return (
    <div id="about">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-6">
            {" "}
            <img src="static/img/about.jpg" className="img-responsive" alt="" />{" "}
            <div className="about-video">
              <iframe width="520" height="315" src="https://www.youtube.com/embed/WHBc0HNzkus?si=w4zqaew6SFQ7woNv" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            </div>
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="about-text">
              <h2>Про нас</h2>
              <p className="about_main">Обробляємо всі види документів, індексуємо їх для пошуку в базі даних, створюємо реєстри та проводимо каталогізацію наявних документів. IT - Documentum пропонує своїм замовникам оцифрування та створення електронних реєстрів (архівів) підприємств всіх форм власності. Наші рішення дозволяють оптимізувати роботу з великими обсягами інформаційних ресурсів. </p>
              <div className="about_wrap">
                <h3>Чому саме ми?</h3>
                <div className="list-style">
                  <div className="col-lg-6 col-sm-6 col-xs-12">
                    <ul>
                      <li>Зручність та швидкість копіювання документа або його частини без втрати якості</li>
                      <li>Можливість необмеженого тиражування копій</li>
                      <li>Простота та зручність організації̈ доступу користувачів до інформації̈ та створення ієрархічних структур</li>
                      <li>Можливість збереження первинного вигляду оригіналу документа за умови оцифрування в кольорі з достатньою роздільною здатністю</li>
                    </ul>
                  </div>
                  <div className="col-lg-6 col-sm-6 col-xs-12">
                    <ul>
                      <li>Можливість тривалого зберігання цифрової̈  копії̈ за умови перенесення її на нові фізичні носії̈ інформації̈ та збереження в актуальному графічному форматі з використанням алгоритмів утискання без втрати якості</li>
                      <li>Можливість відтворення з цифрової̈ копії̈ - друкованої̈  копії̈  документа</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
