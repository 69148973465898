import React from "react";
import { Navigation } from "./components/navigation";
import { Header } from "./components/header";
// import { Features } from "./components/features";
import { About } from "./components/about";
import { Services } from "./components/services";
import { Gallery } from "./components/gallery";
// import { Testimonials } from "./components/testimonials";
// import { Team } from "./components/Team";
import { Contact } from "./components/contact";
import SmoothScroll from "smooth-scroll";
import { GoogleOAuthProvider} from '@react-oauth/google';
import "./App.css";
import Reg from "./components/Reg";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = () => {

  return (
    <div>
      <Navigation />
      <Header />
      {/* <Features data={landingPageData.Features} /> */}
      <About />
      <Services />
      <GoogleOAuthProvider clientId="887552726074-v3cvvsee8nh76ovrk1qe4asmia4p59ut.apps.googleusercontent.com">
        <Reg/>
      </GoogleOAuthProvider>
      <Gallery />
      {/* <Testimonials data={landingPageData.Testimonials} />
      <Team data={landingPageData.Team} /> */}
      <Contact />
    </div>
  );
};

export default App;
