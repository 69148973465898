import React, { useEffect, useState } from "react";

export const Services = () => {

  const [data, setData] = useState([])

  useEffect(() => {
  setData([
    {
      "icon": "fa fa-image",
      "name": "Сканування",
      "text": "Використання сканерів різних типів, залежно від виду документів та їх стану. Налаштування параметрів сканування, таких як роздільна здатність, кольоровий режим, формат файлу (JPEG, TIFF, PDF тощо)."
    },
    {
      "icon": "fa fa-eye",
      "name": "Обробка зображень",
      "text": "Використання програмного забезпечення для оптичного розпізнавання тексту, для перетворення сканованих зображень у текстові файли."
    },
    {
      "icon": "fa fa-cloud-download",
      "name": "Зберігання та управління",
      "text": "Збереження оцифрованих документів у відповідних форматах на локальних серверах, у хмарних сховищах або спеціалізованих системах управління документами. Забезпечення резервного копіювання для захисту від втрати даних."
    },
    {
      "icon": "fa fa-copy",
      "name": "Доступ і поширення",
      "text": "Надання доступу до оцифрованих документів через онлайн-бази даних або інші платформи. Забезпечення зручного пошуку та навігації для користувачів за допомогою інтерфейсів, що підтримують  пошук за багатьма критеріями."
    },
    {
      "icon": "fa fa-shield",
      "name": "Захист інформації",
      "text": "Заходи безпеки для захисту конфіденційних даних."
    },
    {
      "icon": "fa fa-folder-open",
      "name": "Електронний архів",
      "text": "Розробка та впровадження рішень для створення та підтримки електронних архівів документів."
    }
  ])
  }, [])
  
  return (
    <div id="services" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Наші послуги</h2>
          <p>
          Обʼєднуємо минуле з майбутнім, завдяки сучасним технологіям!
          </p>
        </div>
        <div className="row">
          {data
            ? data.map((d, i) => (
                <div key={`${d.name}-${i}`} className="col-md-4">
                  {" "}
                  <i className={d.icon}></i>
                  <div className="service-desc">
                    <h3>{d.name}</h3>
                    <p>{d.text}</p>
                  </div>
                </div>
              ))
            : "loading"}
        </div>
      </div>
    </div>
  );
};
