import { Image } from "./image";
import React from "react";
import { useState, useEffect } from "react";

export const Gallery = () => {

  const [gal, setGal] = useState([])

  useEffect(() => {
    setGal([
      {
        "title": "Державна наукова установа 'Український інститут науково-технічної експертизи та інформації' (Сканування дисертаційних робіт та дослідно-конструкторських робіт) - 2020 рік.",
        "largeImage": "static/img/portfolio/ukrintei_logo.jpg",
        "smallImage": "static/img/portfolio/ukrintei_logo.jpg"
      },
      {
        "title": "Департамент з питань реєстрації виконавчого органу Київської міської ради (КМДА) (Упорядкування реєстраційних справ та складання електронного реєстру) - 2020, 2021, 2023, 2024 роки",
        "largeImage": "static/img/portfolio/kmda.jpg",
        "smallImage": "static/img/portfolio/kmda.jpg"
      },
      {
        "title": "Охоронна компанія 'А1' (Створення електронного реєстру договорів підприємства з гіперпосиланнями на сканкопію договору) - 2023 рік",
        "largeImage": "static/img/portfolio/security.jpg",
        "smallImage": "static/img/portfolio/security.jpg"
      },
      {
        "title": "ПрАТ Лантманнен Акса (Створення електронного реєстру з посиланнями на документ) - 2023 рік",
        "largeImage": "static/img/portfolio/lant.jpg",
        "smallImage": "static/img/portfolio/lant.jpg"
      },
      {
        "title": "Східне міжрегіональне управління Міністерства юстиції (оцифрування актів реєстрації цивільного стану) - 2024 рік.",
        "largeImage": "static/img/portfolio/drx.jpg",
        "smallImage": "static/img/portfolio/drx.jpg"
      },
      {
        "title": "Комунальне підприємство Київської міської ради 'Київське міське бюро технічної інвентаризації' (Створення електронної бази даних технічної та правовстановлюючої документації на об'єкти нерухомого майна) - 2022 - 2024 роки.",
        "largeImage": "static/img/portfolio/bti.jpg",
        "smallImage": "static/img/portfolio/bti.jpg"
      },
      {
        "title": "ТОВ 'Академія сучасної освіти' (Створення реєстру архівної документації з вказівкою на короб з документацією) - 2022 рік",
        "largeImage": "static/img/portfolio/A.jpg",
        "smallImage": "static/img/portfolio/A.jpg"
      },
      {
        "title": "ТОВ 'Енергофінанс' (Створення електронного реєстру договорів підприємства з посиланням на сканкопію договору) - 2023 рік",
        "largeImage": "static/img/portfolio/energo.jpg",
        "smallImage": "static/img/portfolio/energo.jpg"
      },
      {
        "title": "Державне підприємство 'Інфоресурс' (Різні послуги, пов'язані з діловою сферою, послуги архіваріуса) - 2024 рік",
        "largeImage": "static/img/portfolio/info.jpg",
        "smallImage": "static/img/portfolio/info.jpg"
      }
    ])
    
  }, [])
  



  return (
    <div id="portfolio" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Партнери</h2>
          <p>
            Виконуємо роботи з оцифрування як на території нашої компанії, в офісі, архіві так і на території наших замовників. Усі роботи виконуємо як на власному обладнанні, так і на обладнанні замовника. 
          </p>
        </div>
        <div className="row">
          <div className="portfolio-items">
            {gal.length > 0 ? (
              gal.map((d, i) => (
                <div key={`${d.title}-${i}`} className="col-sm-6 col-md-4 col-lg-4">
                  <Image
                    title={d.title}
                    largeImage={d.largeImage}
                    smallImage={d.smallImage}
                  />
                </div>
              ))
            ) : (
              <p>Loading...</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
