import React, { useState } from 'react';
import { useGoogleLogin } from '@react-oauth/google';

export default function Reg() {
  const [userInfo, setUserInfo] = useState(null);

  const login = useGoogleLogin({
    onSuccess: async (response) => {
      console.log(response);
      const accessToken = response.access_token;

      // Получаем информацию о пользователе
      const res = await fetch('https://www.googleapis.com/oauth2/v3/userinfo', {
        headers: {
          'Authorization': `Bearer ${accessToken}`,
        },
      });

      const data = await res.json();
      const email = data.email;
      const name = data.name;

      // Сохраняем информацию о пользователе в состоянии
      setUserInfo({ email, name });

      // Отправляем данные на сервер Flask
      await fetch('https://api.itdocumentum.com.ua/api/saveUser', { // Убедитесь, что этот URL соответствует вашему Flask-серверу
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, name }),
      });

      // Перенаправляем на внешний сайт после успешной авторизации
      window.location.href = 'https://api.itdocumentum.com.ua';
    },
    onError: () => {
      console.log('Ошибка при авторизации');
    },
  });

  return (
    <div id="regystry" className="regystry">
      <div className="overlay">
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-md-offset-2 intro-text">
              <h2>Відкрийте двері до безмежної зручності з нашим електронним архівом: зберігайте, шукайте та діліться важливими документами за лічені секунди!</h2>
              <button onClick={() => login()} className="btn btn-custom btn-lg page-scroll">
                Спробувати
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
