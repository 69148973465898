import React from "react";

export const Header = () => {
  return (
    <header id="header">
      <div className="intro">
        <div className="overlay">
          <div className="container">
            <div className="row">
              <div className="col-md-8 col-md-offset-2 intro-text">
                <h1>
                  Ми IT-Documentum
                  <span></span>
                </h1>
                <p>Невелика компанія з великим досвідом роботи з документами з 2022 року. Компанія надає послуги сканування, створення електронних реєстрів та ручного введення даних операторами.</p>
                {/* <a
                  href="#features"
                  className="btn btn-custom btn-lg page-scroll"
                >
                  Learn More
                </a>{" "} */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
